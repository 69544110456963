import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Support from "../../../images/ecommerce/benefits/support.svg";
import MoneyBack from "../../../images/ecommerce/benefits/money.svg";
import Warranty from "../../../images/ecommerce/benefits/warranty.svg";

const BenefitsSectionMicroREC = () => {
  return (
    <Box
      sx={{
        paddingTop: 5,
      }}
    >
      <Container maxWidth={"lg"} margin={0} justifyContent={"space-around"}>
        <Grid
          container
          justifyContent={"space-around"}
          alignItems={"flex-start"}
        >
          <Grid container item textAlign={"center"} xs={4} sm>
            <Grid item xs={12}>
              <img src={MoneyBack} style={{ width: "50px" }}></img>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                component="div"
                textAlign={"center"}
                sx={{ color: "#FFA100", fontWeight: "600", fontSize: 13 }}
              >
                Risk free: 14 days money back guarantee
              </Typography>
            </Grid>
          </Grid>
          <Grid container item textAlign={"center"} xs={4} sm>
            <Grid item xs={12}>
              <img src={Warranty} style={{ width: "50px" }}></img>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                sx={{ color: "#FFA100", fontWeight: "600", fontSize: 13 }}
                component="div"
              >
                2 years warranty
              </Typography>
            </Grid>
          </Grid>
          <Grid container item textAlign={"center"} xs={4} sm>
            <Grid item xs={12}>
              <img src={Support} style={{ width: "50px" }}></img>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                sx={{ color: "#FFA100", fontWeight: "600", fontSize: 13 }}
                component="div"
              >
                Personalized support
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BenefitsSectionMicroREC;
