import React from "react";
import { useShoppingCart } from "use-shopping-cart";
import { Button, Grid } from "@mui/material";
import { viewCartEventGA } from "../../../utils/ga-events";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";

const CheckoutButton = () => {
  const { cartDetails } = useShoppingCart();

  const { handleCartClick, totalPrice } = useShoppingCart();

  const checkout = () => {
    handleCartClick();
    viewCartEventGA(cartDetails, totalPrice);
  };

  return (
    <Grid
      item
      justifyContent={"center"}
      sx={{ textAlign: "center", marginTop: "0em", marginBottom: "4em" }}
      xs={12}
    >
      <Button
        onClick={checkout}
        color="primary"
        variant="contained"
        endIcon={<AddShoppingCartOutlinedIcon />}
        sx={{
          padding: "8px 30px",
          marginTop: "1em",
          borderRadius: "10px",
        }}
      >
        Go to Checkout
      </Button>
    </Grid>
  );
};

export default CheckoutButton;
