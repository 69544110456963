import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import compatibilityJson from "../../../data/development-only/compatibility.json";
import whatsApp from "/src/images/whatsApp.png";
import gmail from "/src/images/gmail.png";

function MyComponent() {
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [beamsplitter, setBeamsplitter] = useState("");
  const [showBlock, setShowBlock] = useState("");

  const [isShown, setIsShown] = useState(false);

  const HandleClick = (event) => {
    setIsShown(true);
  };

  const showBlockFunction = () => {
    if (
      brand === "Support" ||
      model === "Support" ||
      beamsplitter === "Support" ||
      (brand === "Zeiss" &&
        model === "Lumera 300" &&
        beamsplitter === "Adapter")
    ) {
      setShowBlock("Support");
    } else if (
      (compatibilityJson["SLIT LAMP"][brand] != null &&
        compatibilityJson["SLIT LAMP"][brand][model] != null &&
        compatibilityJson["SLIT LAMP"][brand][model][beamsplitter] != null) ||
      (compatibilityJson["MICROSCOPE"][brand] != null &&
        compatibilityJson["MICROSCOPE"][brand][model] != null &&
        compatibilityJson["MICROSCOPE"][brand][model][beamsplitter] != null)
    ) {
      setShowBlock("Compatible");
    } else if (
      compatibilityJson["MICROSCOPE"][brand] != null &&
      compatibilityJson["MICROSCOPE"][brand][model] != null &&
      compatibilityJson["MICROSCOPE"][brand][model][beamsplitter] === null
    ) {
      setShowBlock("NotCompatible");
    } else if (
      compatibilityJson["SLIT LAMP"][brand] != null &&
      compatibilityJson["SLIT LAMP"][brand][model] != null &&
      compatibilityJson["SLIT LAMP"][brand][model][beamsplitter] === null
    ) {
      setShowBlock("CompatibleOptirec");
    } else {
      setShowBlock("");
    }
  };

  const categories = Object.keys(compatibilityJson);
  const brands =
    category && compatibilityJson[category]
      ? Object.keys(compatibilityJson[category])
      : [];
  const models =
    brand && compatibilityJson[category] && compatibilityJson[category][brand]
      ? Object.keys(compatibilityJson[category][brand])
      : [];

  useEffect(() => {
    setBrand("");
    setModel("");
  }, [category]);

  useEffect(() => {
    setModel("");
  }, [brand]);

  const handleChangeBeamSplitter = (event) => {
    setBeamsplitter(event.target.value);
  };

  useEffect(() => {
    showBlockFunction();
  }, [brand, model, beamsplitter]);

  return (
    <div
      style={{
        paddingBottom: "3em",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#F2F2F2",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <a class="anchor" id="Compatibility"></a>
      <div
        style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "1200px" }}
      >
        <div className="block_compat">
          <div>
            <h1>Check your microscope or slit lamp compatibility</h1>
            <div>
              We are continually verifying the compatibilities of our MicroREC.
              Our microscope adaptor has compatibility with 90% of the
              microscope and more than 50% of the slit lamps.
            </div>
          </div>
        </div>
        <div
          className="dropdown_compat"
          style={{ paddingBottom: isShown ? "9em" : "3em" }}
        >
          <FormControl style={{ minWidth: 220 }} margin="normal">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              label="Find your device category"
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories.map((cat) => (
                <MenuItem value={cat}>
                  {cat
                    .toLowerCase()
                    .split(" ")
                    .map(
                      (word) => word.charAt(0).toUpperCase() + word.substring(1)
                    )
                    .join(" ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ minWidth: 220 }} margin="normal">
            <InputLabel id="brand-label">Brand</InputLabel>
            <Select
              value={brand}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Find your device brand"
              onChange={(e) => setBrand(e.target.value)}
            >
              {brands.map((br) => (
                <MenuItem value={br}>{br}</MenuItem>
              ))}
              <MenuItem value={"Support"}>I don't know</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={{ minWidth: 220 }} margin="normal">
            <InputLabel id="model-label">Model</InputLabel>
            <Select
              value={model}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Find your device model"
              onChange={(e) => setModel(e.target.value)}
            >
              {models.map((mod) => (
                <MenuItem value={mod}>{mod}</MenuItem>
              ))}
              <MenuItem value={"Support"}>I don't know</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={{ minWidth: 220 }} margin="normal">
            <InputLabel id="beamsplitter-label">Need beamsplitter</InputLabel>
            <Select
              value={beamsplitter}
              onChange={handleChangeBeamSplitter}
              label="Need Beamsplitter?"
            >
              <MenuItem value={"Adapter"}>I already have one</MenuItem>
              <MenuItem value={"Beamsplitter"}>I need one</MenuItem>
              <MenuItem value={"Support"}>I don't know</MenuItem>
            </Select>
          </FormControl>
          {/* </div> */}
          <button
            className="mission"
            onClick={function (event) {
              HandleClick();
            }}
          >
            Check compatibility
          </button>

          {isShown
            ? (() => {
                switch (showBlock) {
                  case "Support":
                    return (
                      <p className="compat_text2">
                        We are happy to help! Feel free to contact us:
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1em",
                          }}
                        >
                          <a
                            href="https://wa.me/491782064226?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={whatsApp}
                              style={{ height: "50px", marginRight: "1em" }}
                              alt="WhatsApp"
                            ></img>
                          </a>
                          <a
                            href="mailto:info+contact@customsurgical.co"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={gmail}
                              style={{ height: "35px" }}
                              alt="Gmail"
                            ></img>
                          </a>
                        </div>
                      </p>
                    );
                  case "Compatible":
                    return (
                      <p className="compat_text1">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <div
                              style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: "#8F4FFF",
                                borderRadius: "50px",
                                marginRight: "1em",
                              }}
                            ></div>
                          </div>
                          <div>
                            Your device is{" "}
                            <b style={{ color: "#8F4FFF" }}>compatible</b> with
                            the{" "}
                            {compatibilityJson["SLIT LAMP"][brand] != null &&
                              compatibilityJson["SLIT LAMP"][brand][model] !=
                                null &&
                              compatibilityJson["SLIT LAMP"][brand][model][
                                beamsplitter
                              ] != null && (
                                <b>
                                  {
                                    compatibilityJson["SLIT LAMP"][brand][
                                      model
                                    ][beamsplitter]
                                  }
                                </b>
                              )}
                            {compatibilityJson["MICROSCOPE"][brand] != null &&
                              compatibilityJson["MICROSCOPE"][brand][model] !=
                                null &&
                              compatibilityJson["MICROSCOPE"][brand][model][
                                beamsplitter
                              ] != null && (
                                <b>
                                  {" "}
                                  {
                                    compatibilityJson["MICROSCOPE"][brand][
                                      model
                                    ][beamsplitter]
                                  }
                                </b>
                              )}
                          </div>
                        </div>{" "}
                      </p>
                    );
                  case "NotCompatible":
                    return (
                      <p className="compat_text1">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <div
                              style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: "rgba(32, 32, 38, 0.4)",
                                borderRadius: "50px",
                                marginRight: "1em",
                              }}
                            ></div>
                          </div>
                          <div>
                            Your device is <b>not compatible</b> with the
                            MicroREC right now.
                          </div>
                        </div>
                        <div style={{ marginTop: "1em" }}>
                          If you would like to get more information about when
                          would it be available please{" "}
                          <a
                            className="links_post"
                            target="_blank"
                            rel="noreferrer"
                            href="https://wa.me/491782064226?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
                          >
                            contact us
                          </a>
                          .
                        </div>
                      </p>
                    );
                  case "CompatibleOptirec":
                    return (
                      <p className="compat_text1">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <div
                              style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: "#8F4FFF",
                                borderRadius: "50px",
                                marginRight: "1em",
                              }}
                            ></div>
                          </div>
                          <div>
                            Your device is <b>not compatible</b> with MicroREC.{" "}
                            <b style={{ color: "#8F4FFF" }}>OptiREC </b> is the
                            best choice for you
                          </div>
                        </div>{" "}
                      </p>
                    );
                  default:
                    return null;
                }
              })()
            : null}
        </div>
        <div style={{ fontSize: "12px", color: "#616161" }}>
          <b>Disclaimer:</b> Our product, MicroREC, is a registered, independent
          innovation by Custom Surgical GmbH. While it is compatible with
          third-party equipment, Custom Surgical is not affiliated with, nor
          endorsed by, any of these brands. All trademarks are the property of
          their respective owners, and any references are made purely for
          compatibility purposes.
        </div>
      </div>
    </div>
  );
}

export default MyComponent;
