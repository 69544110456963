import React, { useContext, useEffect } from "react";
import { myContext } from "../../../utils/provider";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Layout from "../../../components/layout/layout-ecommerce";
import SEO from "../../../components/layout/seo";
import ProductSectionBluetooth from "../../../components/ecommerce/section_bluetooth/product-section";
import FAQsSection from "../../../components/ecommerce/sections_microREC/faqs";
import { CartProvider, useShoppingCart } from "use-shopping-cart";
import useStripeProducts from "../../../components/ecommerce/products/useStripeProducts";
import CartModal from "../../../components/ecommerce/store/cartModal";
import { googleAdsConversionTag } from "../../../utils/google-ads-track";
import "@fontsource/red-hat-display";
import "@fontsource/red-hat-display/700.css";
import { createTheme } from "@mui/material/styles";
import { viewCartEventGA } from "../../../utils/ga-events";
import Grid from "@mui/material/Grid";
import { Button } from "gatsby-theme-material-ui";
import { Check } from "react-feather";
import CheckoutButton from "../../../components/ecommerce/sections_microREC/checkout";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
      contrastText: "#fff",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#3D3838",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = () => {
  const products = useStripeProducts();

  const bluetoothStripe = products.filter((product) => {
    return product && product.name && product.name.includes("BTS");
  });

  const connect = products.filter((product) => {
    return (
      product &&
      product.name &&
      product.name.includes("MicroREC Connect License - 1 year")
    );
  });

  const context = useContext(myContext);

  // Add Goggle Ads ID to track conversion events on /store page - for Launch
  useEffect(() => {
    googleAdsConversionTag(process.env.GOOGLE_ADS_ID);
  });

  const getCurrency = () => {
    if (context.ecommerceDollar) {
      return "USD";
    } else {
      return "EUR";
    }
  };

  return (
    <ThemeProvider theme={cs_theme}>
      <CartProvider
        stripe={process.env.STRIPE_PUBLISHABLE_KEY}
        currency={getCurrency()}
      >
        <Container id="main_page" disableGutters maxWidth={false}>
          <Layout helmetName="Bluetooth Shutter">
            <SEO title="Custom Surgical Store | Bluetooth Shutter" />
            <CartModal />
            <ProductSectionBluetooth
              bluetoothStripe={bluetoothStripe[0]}
              connect={connect[0]}
            />
            <CheckoutButton />
          </Layout>
        </Container>
      </CartProvider>
    </ThemeProvider>
  );
};

export default IndexPage;
